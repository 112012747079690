var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.show)?_c('b-container',{staticClass:"detail-page px-0 pb-0",attrs:{"fluid":""}},[_c('b-container',{staticClass:"banner"},[_c('img',{staticClass:"banner-img",attrs:{"src":_vm.detailEvent.event_image,"alt":""}})]),_c('b-container',{staticClass:"detail-news-content"},[_c('div',{staticClass:"info-news-detail"},[_c('div',{staticClass:"info-news"},[_c('div',{staticClass:"date"},[_vm._v(" "+_vm._s(_vm.getDateTime(_vm.detailEvent.created_at))+" ")]),_c('div',{staticClass:"type-news"},[_vm._v(" "+_vm._s(_vm.detailEvent.event_description)+" ")])]),_c('h1',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.detailEvent.event_title)+" ")]),_c('div',{staticClass:"description",domProps:{"innerHTML":_vm._s(_vm.detailEvent.event_description)}}),_c('div',{staticClass:"content",domProps:{"innerHTML":_vm._s(_vm.detailEvent.event_content)}})])]),(_vm.relatedEvent && _vm.relatedEvent.length > 0)?_c('b-container',{staticClass:"related-news",attrs:{"fluid":""}},[_c('b-container',{staticClass:"related-news-post"},[_c('div',{staticClass:"section-news"},[_c('div',{staticClass:"section-title"},[_vm._v("Related Post")]),_c('b-row',{staticClass:"list-news"},_vm._l((_vm.relatedEvent.slice(0, 3)),function(event){return _c('b-col',{key:("box-event-" + (event.event_slug)),attrs:{"lg":"4","sm":"6","cols":"12"}},[_c('div',{staticClass:"news-box"},[_c('router-link',{staticClass:"thumbnail",attrs:{"to":{
                                    name: 'EventDetail',
                                    params: {
                                        slug: event.event_slug,
                                    },
                                }}},[_c('img',{attrs:{"src":event.event_image,"alt":""}})]),_c('div',{staticClass:"content-news"},[_c('div',{staticClass:"info-news"},[_c('div',{staticClass:"date"},[_vm._v(" "+_vm._s(_vm.getDateTime(event.created_at))+" ")]),_c('router-link',{attrs:{"to":{
                                            name: 'EventDetail',
                                            params: {
                                                slug: event.event_slug,
                                            },
                                        }}})],1),_c('router-link',{attrs:{"to":{
                                        name: 'EventDetail',
                                        params: {
                                            slug: event.event_slug,
                                        },
                                    }}},[_c('h3',{staticClass:"title"},[_vm._v(" "+_vm._s(event.event_title)+" ")])])],1)],1)])}),1)],1)])],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }